







import React,{ useRef} from 'react';
import {Grid, Typography, Box, Container, Card, CardMedia, CardContent } from '@mui/material';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStyles from './styles';

const NotFoundComponent = ({ language, data}) => {

    const classes = useStyles();


    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  
    return(
    <>
    <Container maxWidth="md">
      <Box mt={5}>
      <Typography component="h1" style={{fontWeight:800, fontSize:matchesSm ? '40px' : '56px', lineHeight:matchesSm ? '48px' : '64px'}}>{language.english ? 'The page you are looking for seems to have moved or cannot be found.' : 'Is cosúil gur bhog an leathanach atá uait nó nach féidir é a aimsiú.'}</Typography>
      </Box>
      <Box mt={5}>
      <Typography style={{fontWeight:400, fontSize:'21px', lineHeight:'29px'}}>{language.english ? 'It looks like nothing was found at this location. Maybe try the navigation or search option above, or start from scratch.': 'Tá an chuma ar an scéal nach bhfuarthas aon rud ag an suíomh seo. B’fhéidir triail a bhaint as an rogha nascleanúna nó cuardaigh thuas, nó tosú ón tús.'}</Typography>
      <br />
      <Typography gutterBottom style={{fontWeight:400, fontSize:'21px', lineHeight:'29px'}}>{language.english ? 'Have you checked out some of our case studies below?' : 'An bhfuil seiceáil déanta agat ar roinnt dár gcás-staidéir thíos?'}</Typography>
      </Box>
      </Container>
      <Grid container>
        <Container maxWidth="xl">
            <Grid item xs={12} mb={4}>
              <Grid container >
                <Grid item xs={12} mt={10} mb={3} display="flex" justifyContent="center">
                    <Box>
                      <Link to={language.english ? '/case-studies' : '/cas-staideir'} color="inherit" style={{textDecoration: 'none'}}><Typography variant="bodyheading" color="#594E94" component="h2">{language.english ? 'Case Studies' : 'Cás-Staidéir'}</Typography></Link>
                    </Box>
                </Grid>
                {data.caseStudies.nodes.map((item, index) => {
                    return(
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} p={2} pl={2} pr={2} pb={4}>
                    <Link to={language.english ? `/case-studies/${item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}` : `/cas-staideir/${item.acf_case_study.titleIrish.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s!?]/g,'').split(' ').join('-').toLowerCase()}`} color="inherit" style={{textDecoration: 'none'}}>
                        <Card className={classes.card1}>
                        <CardMedia height="140"><GatsbyImage style={{height:'200px'}} imgStyle={{borderRadius:'15px 15px 0px 0px'}} image={getImage(item.acf_case_study?.heroThumbnail?.localFile?.childImageSharp)} /></CardMedia>
                        <Box p={1}>
                        <CardContent>
                            <Typography gutterBottom variant="cardheading2" component="h3">
                            {language.english ? item.title : item.acf_case_study.titleIrish}
                            </Typography>
                            <Typography gutterBottom variant="card3">
                            {language.english ? item.acf_case_study.caption : item.acf_case_study.captionIrish}
                            </Typography>
                        </CardContent>
                        </Box>
                        </Card>
                    </Link>
                    </Grid>
                    )
                })}
            </Grid>
          </Grid>
        </Container>
    </Grid>
    </>     
   )
}

export default NotFoundComponent;