import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MainComponent from '../components/404/MainComponent';
import LanguageContext from "../context/LanguageContext";
import { Box } from "@mui/material";

const NotFoundPage = ({ data }) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return null;


  return (
    <LanguageContext.Consumer>
    {language => (
      <Layout>
        <Seo title="404: Not Found" />
        <Box role="main">
          <MainComponent data={data} language={language} />
        </Box>
      </Layout>
     )}
    </LanguageContext.Consumer>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
  caseStudies:allWpCaseStudy(limit:4){
    nodes{
      title
      categories{
        nodes{
        name
        caseStudiesCategories{
            categoryNameIrish
          }
        }
      }
      acf_case_study{
      titleIrish
      caption
      captionIrish
      heroThumbnail{
        localFile{
          childImageSharp {
            gatsbyImageData( 
              placeholder: BLURRED
            layout: FULL_WIDTH
            )
          }
        }
      }
    }
    }
  }
  }
  `

